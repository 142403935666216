.line-bottom {
    background-color: #70561A;
    width: 15%;
    height: 1px;
    margin-top: 20px;
    margin-bottom: 16px;
}

.branch{ 
    color:#545454 ;
    position: relative;
    padding-right :  20px;
}

.branch::before {
    content: '';
    position: absolute;
    width: 7px;
    height: 7px;
    right: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #70561A;
}
