.wpo-section-title,
.wpo-section-title-s2,
.wpo-section-title-s3{
	margin-bottom: 60px;
	position: relative;
	padding-left: 25px;
	text-align: right;
	&:before {
		position: absolute;
		left: 0;
		top: 0;
		width: 1px;
		height: 100%;
		background: $theme-primary-color;
		content: "";
	}

	@include media-query(991px) {
		margin-bottom: 40px;
	}

	@include media-query(575px) {
		margin-bottom: 30px;
	}

	span {
		font-weight: 400;
		font-size: 20px;
		line-height: 25px;
		color: $theme-primary-color;
	}

	h2 {
		font-weight: 400;
		font-size: 40px;
		line-height: 55px;
		margin: 0;
		position: relative;
		text-transform: capitalize;
		font-family: $heading-font;
		margin-top: 0;
		margin-bottom: 0;
		color: $white;
		text-align: right;

		@include media-query(767px) {
			font-size: 32px;
			line-height: 40px;
		}

		@include media-query(330px) {
			font-size: 28px;
		}

	}

	p {
		font-size: 18px;
	}
	em ,.MuiSelect-select{
        font-size: 18px;
		color: gray;
	}

}

// .wpo-section-title-s2

.wpo-section-title-s2,
.wpo-section-title-s3 {
	text-align: center;
	padding-bottom: 20px;
	padding-left: 0;

	&:before {
		left: 50%;
		bottom: 0;
		top: auto;
		width: 80px;
		height: 1px;
		transform: translateX(-50%);
	}
}

// .wpo-section-title-s3
.wpo-section-title-s3 {
	h2{
		color: $dark-gray;
	}
}