* {
    scrollbar-color: #af914e transparent; /* set the color of the scrollbar */
    scrollbar-width: none; /* set the width of the scrollbar */
}

*::-webkit-scrollbar {
    width: 8px; /* set the width of the scrollbar */
    height: 8px; /* set the height of the scrollbar */
}
iframe,.frame{
    scrollbar-color: #af914e transparent !important; /* set the color of the scrollbar */
    scrollbar-width: thin !important; /* set the width of the scrollbar */
    overflow-y: hidden !important;
    font-size: 0 !important;
}