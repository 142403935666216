.theme-btn,
.theme-btn-s2 {
	background: transparent;
	color: $theme-primary-color;
	display: inline-block;
	font-weight: 600;
	padding: 12px 55px 17px;
	border: 1px solid $theme-primary-color;
	border-radius: 0px;
	text-transform: capitalize;
	-webkit-backface-visibility: hidden;
	z-index: 1;
	position: relative;
	font-weight: 400;
	font-size: 20px;
	line-height: 25px;
	transition: all .3s;


	@include media-query(991px) {
		font-size: 18px;
	}

	@include media-query(767px) {
		padding: 9px 18px 14px;
		font-size: 14px;
	}


	&:after {
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		width: 100%;
		height: 0;
		border-radius: 0px;
		background: darken($theme-primary-color, 8%);
		transition: opacity 0.5s ease-out;
		z-index: -1;
		transition: all .3s;
	}

	&:before {
		position: absolute;
		content: '';
		top: -6px;
		right: -7px;
		width: 101%;
		height: 101%;
		border-radius: 0px;
		z-index: -1;
		border: 1px solid $theme-primary-color;
	}

	&:hover,
	&:focus,
	&:active {
		color: $white;
		padding: 14.5px 55px;

		&:after {
			height: 100%;
		}

		@include media-query(767px) {
			padding: 11.5px 18px;
		}
	}
}

.theme-btn-s2 {
	background: $theme-primary-color;
	color: $white;
	display: inline-block;
	border: 1px solid $theme-primary-color;
	text-transform: capitalize;
	padding: 12px 55px;

	z-index: 1;
	position: relative;


	@include media-query(991px) {
		font-size: 14px;
	}

	@include media-query(767px) {
		padding: 12px 18px;
		font-size: 13px;
	}

	&:hover,
	&:focus,
	&:active {
		background: darken($theme-primary-color, 5%);
		color: $white;
		padding: 12px 55px;

		@include media-query(767px) {
			padding: 12px 18px;
		}
	}

	&:hover:after {
		opacity: 1;
	}
}



.view-cart-btn {
	display: block;
	margin-top: 15px;
	border-radius: 5px;
	padding: 10px 45px;
	background: $theme-primary-color;
	color: $white;



	&:after {
		display: none;
	}
	&:before {
		display: none;
	}

	&:hover {
		background-color: darken($theme-primary-color, 8%);
		color: $white;
	}

	&.s1 {
		background: #ddd;

		&:hover {
			background-color: darken(#ddd, 8%);
			color: $white;
		}
	}

}