/*------------------------------------------------------------------
[Master Stylesheet]
Theme Name:     Avukat – Lawyer and Attorney React Template
Version:        1.0.0
-------------------------------------------------------------------*/


/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------

1. General
	1.1 Theme Reset Style
	1.2 Global Elements

2. header
	2.1 topbar
	2.2 navigation

3. content
	3.1 wpo-hero-slider
	3.2 wpo-about-section
	3.3 wpo-practice-section
	3.4 wpo-testimonials-section
	3.5 wpo-case-section
	3.6 wpo-fun-fact-section
	3.7 wpo-team-section
	3.8 wpo-contact-section
	3.9 wpo-blog-section

4. wpo-footer

5. Home-style-2
	5.1 wpo-about-section-s2
	5.2 wpo-practice-section-s2
	5.3 wpo-contact-section-s2

6. Home-style-3
	6.1 wpo-features-section
	6.2 wpo-about-section-s3
	6.3 wpo-practice-section-s3
	6.4 wpo-testimonials-section-s2
	6.5 wpo-case-section-s2

7. Home-style-3
	7.1 wpo-about-section-s3
	7.2 wpo-case-section-s3

8. wpo-Attorney-single

9. wpo-service-single-section

10. wpo-case-section

11. wpo-cart-page-style

12. wpo-checkout-page-style

13. wpo-shop-page

14. wpo-shop-single-page

15. wpo-pricing-page

16. wpo-faq-page

17. wpo-blog-pg-section

18. wpo-blog-single-section

19. wpo-contact-pg-section

20. wpo-terms-section

21. error-404-section

----------------------------------------------------------------*/


/*------------------------------------------------------------------
1. General
----------------------------------------------------------------*/

// helpers
@import "helpers/variables";
@import "helpers/mixins";

// base
@import "base/base";


// components
@import "components/section-title";
@import "components/buttons";
@import "components/form";
@import "components/social-links";
@import "components/page-title";
@import "components/blog-sidebar";
@import "components/pagination";

// layout
@import "layout/header";
@import "layout/hero-slider";
@import "layout/footer";

// components
@import "components/sticky-header";


// page
@import "page/home-default";

@import "page/_home-style2";

@import "page/_home-style3";

@import "page/_home-style4";


@import "page/_attorney";

@import "page/_service";

@import "page/_case";

@import "page/_cart";

@import "page/_checkout";

@import "page/_shop";

@import "page/_shop-single";

@import "page/_pricing";

@import "page/faq";

@import "page/_blog";

@import "page/_blog-single";

@import "page/_contact";

@import "page/_terms";

@import "page/_error-404";




body {
  scrollbar-color: #d9d9d9 transparent; /* set the color of the scrollbar */
  scrollbar-width: thin; /* set the width of the scrollbar */
}

body::-webkit-scrollbar,
body::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-track,
body::-o-scrollbar,
body::-o-scrollbar-thumb,
body::-o-scrollbar-track {
  width: 6px; /* width of the scrollbar */
}

body::-webkit-scrollbar {
  background: transparent; /* background color of the scrollbar track */
}

body::-webkit-scrollbar-thumb,
body::-o-scrollbar-thumb {
  background-color: #d9d9d9; /* color of the scrollbar thumb */
  border-radius: 5px; /* border radius of the scrollbar thumb */
}
