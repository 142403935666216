ul.smothscroll {
    position: fixed;
    bottom: 25px;
    right:-20px;
    list-style: none;
    z-index: 99;
    display: flex;
    gap:5px;
    flex-direction: column;
    align-items: center;
}
ul.smothscroll a {
    background-color: rgba(227, 181, 118, 0.7);
    width: 45px;
    height: 45px;
    line-height: 45px;
    border-radius:50%;
    display: block;
    text-align: center;
    color: #fff;
    background-size: 200%,1px;
    background-position: 0;
    border: 2px solid #e3b576;
}
ul.smothscroll button {

    background-color:#25d366;
    width: 50px;
    height: 50px;
    line-height: 45px;
    border-radius:50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fff;
    background-size: 200%,1px;
    background-position: 0;
    border:none;
}
ul.smothscroll a:hover {
    background-color: #e3b576;
    color: #fff;
}

@media(max-width:767px){
    ul.smothscroll a {
        width: 30px;
        height: 30px;
        line-height: 25px;
    }
}