/*------------------------------------
	blog sidebar
------------------------------------*/
.blog-sidebar {
	@include widther(992px) {
		padding-left: 45px;
	}

	@include media-query(991px) {
		margin-top: 80px;
	}

	@include media-query(767px) {
		margin-top: 60px;
	}

	.widget {

		background: $section-bg-color-s2;
		padding: 40px;
		border-radius: 5px;

		@include media-query(1400px) {
			padding: 25px;
		}

		h3 {
			font-size: 25px;
			color: $dark-gray;
			margin: 0 0 1.3em;
			position: relative;
			text-transform: capitalize;
			font-family: $heading-font;
			font-weight: 400;
			border-radius: 5px;
		}
	}

	> .widget + .widget {
		margin-top: 65px;
	}


	/*** search-widget ***/
	.search-widget {
		form div {
			position: relative;
		}

		input {
			background-color: $white;
			height: 70px;
			font-size: 16px;
			padding: 6px 50px 6px 20px;
			border: 0;
			border-radius: 5px;
			box-shadow: none;
			@include transition-time(0.3s);
		}

		input:focus {
			background-color: $white;
		}

		form button {
			background: transparent;
			font-size: 20px;
			color: $white;
			background-color: $theme-primary-color;
			border: 0;
			outline: 0;
			position: absolute;
			right: 10px;
			top: 52%;
			height: 50px;
			line-height: 50px;
			width: 50px;
			border-radius: 6px;
			@include translatingY();
		}
	}


	/*** about-widget ***/
	.about-widget {
		text-align: center;
		background-color: $section-bg-color-s2;
		padding: 45px;
		position: relative;
		z-index: 1;

		@media(max-width:1200px){
          padding: 35px;
		}

		.aw-shape{
			position: absolute;
			right: 0;
			top: 0;
			z-index: -1;
		}
		.img-holder {
			margin-bottom: 25px;
            
			img{
				border-radius: 50%;
				width: 100%;
				max-width: 200px;
			}

		}

		ul{
			list-style: none;
			display: flex;
			justify-content: center;

			li{
				margin-right: 10px;

				&:last-child{
					margin-right: 0;
				}
                a{
					text-decoration: none;
					display: block;
					width: 40px;
					height: 40px;
					line-height: 44px;
					background-color: $white;
					border-radius: 50%;

					&:hover{
						background: $theme-primary-color;
						color: $white;
					}
				}
			}
		}

		h4{
			font-size: 25px;
			font-weight: 400;
			margin-bottom: 15px;
		}

		p {
			font-size: 15px;
			margin-bottom: 1.2em;
		}

		a {
			font-family: $heading-font;
			font-size: 14px;
			color: $heading-color;
			text-decoration: underline;
		}

		a:hover {
			color: $theme-primary-color;
		}
	}


	/*** category-widget ***/
	.category-widget {
		ul {
			list-style: none;
		}

		ul li {
			font-size: 19px;
			position: relative;

			@include media-query(767px) {
				font-size: 16px;
			}

			span {
				position: absolute;
				right: 0;
			}
		}

		ul > li + li {
			margin-top: 12px;
		}

		ul a {
			display: block;
			color: #474f62;
			font-size: 16px;
            padding: 20px;
			background: $white;
			padding-left: 60px;
			position: relative;
			border-radius: 5px;
			font-weight: 600;

			@media(max-width:1200px){
				padding: 10px;
				padding-left: 25px;
				font-size: 14px;
			}

			&:after{
				position: absolute;
				left: 30px;
				top: 50%;
				transform: translateY(-50%);
				content: "\f11c";
				font-family: flaticon_law;
				transform: translateY(-50%);

				@media(max-width:1200px){
					left: 5px;
				}
			}
		}

		ul a:hover,
		ul a.active,
		ul li:hover:before {
			background: $theme-primary-color;
			color: $white;
		}
	}


	/*** recent-post-widget ***/
	.recent-post-widget {
		.post {
			overflow: hidden;
		}

		.posts > .post + .post {
			margin-top: 15px;
			padding-top: 15px;
		}

		.post .img-holder {
			width: 90px;
			float: left;

			img {
				border-radius: 6px;
			}
		}

		.post .details {
			width: calc(100% - 90px);
			float: left;
			padding-left: 20px;

			@media(max-width:1199px){
				padding-left: 8px;
			}

		}

		.post h4 {
			font-size: 16px;
			font-weight: 500;
			line-height: 1.3em;
			margin: 0 0 0.3em;
			font-family: $base-font;

			@include media-query(1199px) {
				margin: 0;
				font-size: 14px;
			}

			@include media-query(767px) {
				font-size: 16px;
			}
		}

		.post h4 a {
			display: inline-block;
			color: $heading-color;
		}

		.post h4 a:hover {
			color: $theme-primary-color;
		}

		.post .details .date {
			font-size: 13px;
			color: $theme-primary-color;

			i {
				display: inline-block;
				padding-right: 7px;
			}
		}
	}


	/*** instagram-widget ***/

	.wpo-instagram-widget{
        
		ul{
			display: flex;
			flex-wrap: wrap;
			list-style: none;
			li{
			 -ms-flex: 0 0 33.33%;
			 -webkit-box-flex: 0;
			 flex: 0 0 33.33%;
			 max-width: 33.33%;
			 margin-bottom: 5px;
			 padding: 0px 3px;
 
			 img{
				 width: 100%;
				 border-radius: 6px;
			 }
			}
		}
	 
	 }


	/*** tag-widget ***/
	.tag-widget {

		ul {
			overflow: hidden;
			list-style: none;
		}

		ul li {
			float: left;
			margin: 0 8px 8px 0;
		}

		ul li a {
			font-size: 15px;
			display: inline-block;
			padding: 5px 10px;
			color: $dark-gray;
			background: $white;
			border-radius: 5px;
		}

		ul li a:hover {
			background: $theme-primary-color;
			color: $white;
		}
	}

	/*** wpo-contact-widget ***/

	.wpo-contact-widget {
        border: 0;
        background: $section-bg-dark-2 ;
        position: relative;
        z-index: 1;
        text-align: center;
        padding: 40px;
		margin-top: 65px;


        @media(max-width:1199px) {
            padding:30px 20px;
        }

        i {
            display: block;
            font-size: 50px;
            color: $theme-primary-color;
        }

        span {
            font-weight: 400;
            font-size: 22px;
            line-height: 30px;
            text-align: center;
            font-family: $heading-font;
            color: $white;
            display: block;
            margin-bottom: 5px;
        }

        h2 {
            font-weight: 400;
            font-size: 40px;
            line-height: 55px;
            color: $white;
            margin-bottom: 20px;

            @media(max-width:1199px) {
                font-size: 30px;
            }
        }

        p {
            color: $white;
            font-size: 18px;
        }


        a {
            display: inline-block;
            padding: 10px 30px;
            background: $theme-primary-color;
            font-weight: 400;
            font-size: 20px;
            line-height: 27px;
            position: relative;
            margin-top: 10px;
            color: $dark-gray;
            font-family: $heading-font;
        }
    }
}


.wpo-blog-pg-section.blog-pg-left-sidebar{
	.blog-sidebar{
		@include widther(992px) {
			padding-left: 0px;
			padding-right: 45px;
		}
	}
}


.wpo-blog-single-section.wpo-blog-single-left-sidebar-section{
	.blog-sidebar{
		@include widther(992px) {
			padding-left: 0px;
			padding-right: 45px;
		}
	}
}